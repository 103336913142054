import 'intersection-observer';
import '../src/app.scss';

import React, {useEffect, type ReactNode, useState} from 'react';
import App, {type AppProps, type AppContext} from 'next/app';
import {Provider, useDispatch} from 'react-redux';
import {I18nextProvider, useSSR} from 'react-i18next';
import {useRouter} from 'next/router';
import Head from 'next/head';

import type {Resource} from 'i18next';

import ModalsGroup from '../src/components/modals-group/modals-group';
import toastFactory from '../vendors/bootstrap/components/toast';

import {loadSettingsOptions, loadUserSettings} from '@/redux/actions/settingsActions';
import {useStore} from '@/redux/store';
import {customEvent as gtmEvent, data as gtmData, getInitialDataLayer} from '@/utils/gtm';
import constantsFactory from '@/utils/constants';
import elementFactory from '@/utils/element';
import cookie from '@/utils/helpers/cookie';
import i18n from '@/utils/i18n';
import {bugsnagStart} from '@/services/bugsnag';
import {getAppMetadata} from '@/utils/_app/getAppMetadata';
import {GlobalStyle} from './styles';

const {COOKIE, LOCALE_DEFAULT} = constantsFactory();

bugsnagStart();

const dispatchInitialDataLayerEvents = () => {
  try {
    const initData = getInitialDataLayer();
    gtmData(initData);
    gtmEvent(`environment_${process.env.ENVIRONMENT}`);
    gtmEvent(`gtm_datalayer_initialized`);
    if ('userId' in initData && initData['userId']) {
      gtmEvent(`user_logged_in`);
    }
  } catch (e) {
    console.log(e);
  }
};

const AppComponents = ({children}: {children: ReactNode}): JSX.Element => {
  // This components wrapper allows the use of hooks within the core app system
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadSettingsOptions());
    dispatch(loadUserSettings('_app'));
  }, []);

  return <>{children}</>;
};

interface AppPageProps extends AppProps {
  err?:
    | (Error & {
        statusCode?: number;
      })
    | null;
  initialI18nStore?: Resource;
  initialLanguage?: string;
}
/**
 * @description The `_app.tsx` component in Next.js serves as the root App component
 * that initializes pages and manages global configurations,
 * allowing for consistent layout, and context across the entire application.
 * @param {NextComponentType} Component The component to render.
 * @param {Record<string, unknown>} pageProps The props for the app components.
 * @param {Error|undefined|null} err Any errors from or to the app.
 * @param {Resource|undefined} initialI18nStore The initial i18n store for the app.
 * @param {string|undefined} initialLanguage The initial language for the app.
 * @return {JSX.Element} The app component to initialize pages.
 */
const MyApp = ({
  Component,
  pageProps,
  err,
  initialI18nStore = {},
  initialLanguage = LOCALE_DEFAULT,
}: AppPageProps) => {
  const store = useStore(pageProps.initialReduxState);
  useSSR(initialI18nStore, initialLanguage);
  const router = useRouter();
  const [shouldInsert, setShouldInsert] = useState(true);
  const isProductPage = router.asPath.includes('/product/');

  const {links: hrefLangLinks} = getAppMetadata({pathname: router.asPath});
  const currentLinkHref = hrefLangLinks[0].href;

  /**
   * @description the effect to check if the `<link rel="alternate" hrefLang="` links
   * have already been inserted, to avoid inserting them multiple times on product page.
   */
  useEffect(() => {
    if (!isProductPage) return;
    const existingLinks = document.querySelectorAll('link[rel="alternate"]');

    if (
      existingLinks.length >= hrefLangLinks.length &&
      existingLinks[0].getAttribute('href') === currentLinkHref
    ) {
      setShouldInsert(false);
    }
  }, [hrefLangLinks.length, currentLinkHref, isProductPage]);

  useEffect(() => {
    dispatchInitialDataLayerEvents();
  }, []);

  useEffect(() => {
    try {
      const $ = elementFactory(document, window);
      window.$ = $;
      window.toast = toastFactory(document, window, $);
    } catch (e) {
      console.log(e);
    }
  }, []);

  // Workaround for https://github.com/vercel/next.js/issues/8592
  return (
    <>
      <Head>
        {shouldInsert &&
          hrefLangLinks.map(({id, ...restLinkProps}) => <link key={id} {...restLinkProps} />)}
      </Head>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <AppComponents>
            <GlobalStyle />
            <ModalsGroup />
            <Component {...pageProps} err={err} />
          </AppComponents>
        </Provider>
      </I18nextProvider>
    </>
  );
};

/**
 * @description The `getInitialProps` function in Next.js is a lifecycle method
 * - Runs on both server-side and client-side
 * - Runs server-side on initial page load
 * - Runs client-side during subsequent client-side navigation
 * - Applies to all pages in the application
 * @param {AppContext} appContext The context of the app.
 * @return {Promise<AppPageProps>} The initial props for the app.
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.req) {
    const requestCookies = appContext.ctx.req.headers.cookie;
    const locale = cookie.extractFromString(COOKIE.LOCALE, requestCookies) || LOCALE_DEFAULT;
    await i18n.changeLanguage(locale);

    return {
      ...appProps,
      /**
       * @description The initial i18n store and language for the app,
       * from server to client.
       */
      initialI18nStore: i18n.services.resourceStore.data,
      initialLanguage: locale,
    };
  }

  return {
    ...appProps,
  };
};

export default MyApp;
