import type {LinkHTMLAttributes} from 'react';

/**
 * @description generates the full URL for the current page path
 * @param {string} pathname
 * @return {string} full page URL
 */
export const getFullPageUrl = (pathname: string) => {
  let fullPageUrl = `${process.env.HOST}${pathname !== '/home' ? pathname : '/'}`;
  if (!fullPageUrl.endsWith('/')) {
    fullPageUrl = fullPageUrl + '/';
  }
  return fullPageUrl;
};

/**
 * @description generates metadata for the app root level.
 * @param {Object} props
 * @param {string} props.pathname the pathname of the current page
 * @return {Record<string, unknown>} metadata for the app
 */
export const getAppMetadata = ({
  pathname,
}: {
  pathname: string;
}): {
  links: (LinkHTMLAttributes<HTMLLinkElement> & {id: string})[];
} => {
  const fullPageUrl = getFullPageUrl(pathname);

  return {
    links: [
      {
        id: 'page-alternate-link-en',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en',
      },
      {
        id: 'page-alternate-link-en-US',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-US',
      },
      {
        id: 'page-alternate-link-en-GB',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-GB',
      },
      {
        id: 'page-alternate-link-en-IE',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-IE',
      },
      {
        id: 'page-alternate-link-en-CA',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-CA',
      },
      {
        id: 'page-alternate-link-en-AU',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-AU',
      },
      {
        id: 'page-alternate-link-en-NZ',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-NZ',
      },
      {
        id: 'page-alternate-link-en-ZA',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'en-ZA',
      },
      {
        id: 'page-alternate-link-X-default',
        rel: 'alternate',
        href: fullPageUrl,
        hrefLang: 'X-default',
      },
    ],
  };
};
